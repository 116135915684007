import { css } from '@emotion/css'

/* eslint-disable no-bitwise */
import * as React from 'react'
import {
  FaCheckCircle,
  FaCircle,
  FaQuestionCircle,
  FaTimesCircle,
} from 'react-icons/fa'
import { HiTrendingDown, HiTrendingUp } from 'react-icons/hi'
import { twMerge } from 'tailwind-merge'
import Tooltip from '../components/Tooltip'
import { brandTypeOptions } from '../options/brandTypeOptions'
import { countryOptions } from '../options/countryOptions'
import { deviceOptions, deviceOptionsV2 } from '../options/deviceOptions'
import { engineOptions } from './engines'
import { languageOptions } from '../options/languageOptions'

import { getFloatPrecision, getRankGroupFromRank, uniqBy } from '../utils'
import { twConfig } from './tailwind'
import { formatEngine } from './engines'
import { MetricPostAggregation } from './Metrics'

type NumberFormatter = (
  num: number,
  opts?: { precision?: number; short?: boolean }
) => number

export function formatOption(options: any, input: any) {
  const found = options.find(
    (d: any) => d.value == input || d.altValue == input
  )
  return found ? found.formatted || found.label : input || '(unknown)'
}

type FormatNumberOptions = {
  precision?: 'auto' | number
  forcePrecision?: boolean
  defaultValue?: string
  short?: boolean
}

export function formatNumber(num: number, opts?: FormatNumberOptions) {
  const fmt = (num: number) => {
    const defaultValue = opts?.defaultValue ?? '-'
    const precision =
      (opts?.precision === 'auto' ? getFloatPrecision(num) : opts?.precision) ??
      0

    if (num === null || num === undefined) {
      return defaultValue
    }

    num = Number(num)

    if (Number.isNaN(num)) {
      return defaultValue
    }

    const re = `\\d(?=(\\d{3})+$)`

    let strNum = num.toFixed(Math.max(0, ~~precision))

    if (!opts?.forcePrecision) {
      strNum = String(parseFloat(strNum))
    }

    const [whole, decimal] = strNum.split('.')

    if (!whole) {
      return strNum
    }

    if (decimal) {
      return `${whole.replace(new RegExp(re, 'g'), '$&,')}.${decimal}`
    }

    return whole.replace(new RegExp(re, 'g'), '$&,')
  }

  if (opts?.short) {
    const abs = Math.abs(num)

    if (abs > 999999) {
      return `${fmt(num / 1000000)} M`
    }

    if (abs > 999) {
      return `${fmt(num / 1000)} k`
    }
  }

  return fmt(num)
}

export function formatCurrency(value: number, opts?: FormatNumberOptions) {
  return `$${formatNumber(value, opts)}`
}

export function formatPercentage(
  input: number,
  opts?: {
    precision?: number
    forcePrecision?: boolean
    format?: NumberFormatter
  }
) {
  const num = (opts?.format ?? formatNumber)(input * 100, {
    precision: opts?.precision ?? 1,
    forcePrecision: opts?.forcePrecision ?? false,
  })
  if (!num || num === '-') {
    return '-'
  }
  return `${num}%`
}

export function formatRank(
  num: number,
  opts?: {
    string?: boolean
    precision?: number
    forcePrecision?: boolean
    row?: { data: { top_ranking_results: { result__item_rank: number }[] }[] }
    hideItemRank?: boolean
  }
) {
  const resultItemRank =
    opts?.row?.data?.[opts.row.data.length - 1]?.['top_ranking_results']?.[0]
      ?.result__item_rank

  if (opts?.string) {
    return (
      formatNumber(num, {
        precision: opts.precision,
        forcePrecision: opts?.forcePrecision,
      }) + (resultItemRank ? ` • ${formatNumber(resultItemRank)}` : '')
    )
  }

  const rankGroup = getRankGroupFromRank(num)

  return (
    <span className="flex items-center gap-3">
      <span className="flex items-center gap-1">
        <FaCircle
          className={twMerge(
            `text-[.8em]`,

            css({ color: twConfig.theme.colors.rankGroup?.[rankGroup] })
          )}
        />
        <span>
          {formatNumber(num, {
            precision: opts?.precision,
            forcePrecision: opts?.forcePrecision,
          })}
        </span>
      </span>{' '}
      {(resultItemRank ?? 0) > 0 && !opts?.hideItemRank ? (
        <span className="flex items-center gap-1">
          <FaCircle className="text-[.8em] text-gray-500 opacity-50" />{' '}
          <span>
            {formatNumber(
              // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
              resultItemRank
            )}
          </span>
        </span>
      ) : null}
    </span>
  )
}

export function formatDevice(
  input: any,
  opts?: { string?: boolean; tooltip?: boolean; short?: boolean; v2?: boolean }
) {
  const tooltip = opts?.tooltip ?? true

  if (!input) {
    if (opts?.string) {
      return ''
    }
    return <span />
  }

  let label = input
  let icon = null
  const found = opts?.v2
    ? deviceOptionsV2.find(d => d.value === input)
    : deviceOptions.find(d => d.value === input)

  if (found) {
    icon = found.icon
    label = found.label
  }

  if (opts?.string) {
    return label
  }

  return opts?.short ?? true ? (
    tooltip ? (
      <Tooltip tooltip={label}>{icon}</Tooltip>
    ) : (
      icon
    )
  ) : (
    <>
      {icon}
      {opts?.short ?? true ? null : ` ${label}`}
    </>
  )
}

export function renderDevice(
  input: any,
  opts?: { short?: boolean; tooltip?: boolean }
) {
  const tooltip = opts?.tooltip ?? true

  if (!input) {
    return <FaQuestionCircle />
  }

  let label = input
  let icon = null

  const found = deviceOptionsV2.find(d => d.value === input)

  if (found) {
    icon = found.icon
    label = found.label
  }

  return opts?.short ?? true ? (
    tooltip ? (
      <Tooltip tooltip={label}>{icon}</Tooltip>
    ) : (
      icon
    )
  ) : (
    <>
      {icon}
      {opts?.short ?? true ? null : ` ${label}`}
    </>
  )
}

export function formatChange(
  input: number,
  opts?: { format?: (...args: any[]) => any; string?: boolean }
) {
  if (typeof input !== 'number') {
    if (opts?.string) {
      return '-'
    }
    return <span>-</span>
  }

  const format = opts?.format ?? formatNumber

  if (opts?.string) {
    if (input < 0) {
      return `${input}`
    }
    if (input > 0) {
      return `+${input}`
    }

    return '0'
  }

  if (input < 0) {
    return (
      <span className="inline-flex items-center gap-1">
        <HiTrendingDown
          className={twMerge(
            `scale-[1.5] transform leading-none text-red-500`,
            css({
              fontSize: '.7em',
            })
          )}
        />
        <span className="leading-none">{format(Math.abs(input))}</span>
      </span>
    )
  }
  if (input > 0) {
    return (
      <span className="inline-flex items-center gap-1">
        <HiTrendingUp
          className={twMerge(
            `scale-[1.5] transform leading-none text-green-500`,
            css({
              fontSize: '.7em',
            })
          )}
        />
        <span className="leading-none">{format(Math.abs(input))}</span>
      </span>
    )
  }
  return <span>-</span>
}

export function formatKeywordV1(
  keyword = {},
  {
    string = false,
    short = false,
    delimiter = ' - ',
    keywords = undefined,
    tooltip = true,
    flexWrap = false,
  } = {}
) {
  const skip = {}

  if (keywords) {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;[
      'engine',
      'engine_code',
      // 'device',
      // 'device_code',
      'country',
      'country_code',
      'language',
      'language_code',
      'location',
    ].forEach(key => {
      // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!skip[key]) {
        // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        skip[key] = keywords[0]?.[key]
          ? // @ts-expect-error  // Property 'map' does not exist on type 'never'.
            uniqBy(keywords.map((d: any) => d[key])).length === 1
          : true
      }
    })
  }

  const phraseKey = 'phrase'
  // @ts-expect-error  // Property 'engine_code' does not exist on type '{}'... Remove this comment to see the full error message
  const engineKey = keyword.engine_code ? 'engine_code' : 'engine'
  // @ts-expect-error  // Property 'device_code' does not exist on type '{}'... Remove this comment to see the full error message
  const deviceKey = keyword.device_code ? 'device_code' : 'device'
  // @ts-expect-error  // Property 'country_code' does not exist on type '{}... Remove this comment to see the full error message
  const countryKey = keyword.country_code ? 'country_code' : 'country'
  // @ts-expect-error  // Property 'language_code' does not exist on type '{... Remove this comment to see the full error message
  const languageKey = keyword.language_code ? 'language_code' : 'language'
  const locationKey = 'location'

  // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const getPhrase = skip[phraseKey]
    ? null
    : () =>
        string ? (
          keyword[phraseKey]
        ) : (
          <span className="text-sm leading-none">{keyword[phraseKey]}</span>
        )
  // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const getEngine = skip[engineKey]
    ? null
    : () =>
        string
          ? // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            formatOption(engineOptions, keyword[engineKey])
          : // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            formatEngine(keyword[engineKey], { tooltip })
  // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const getDevice = skip[deviceKey]
    ? null
    : () =>
        string
          ? // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            formatOption(deviceOptions, keyword[deviceKey])
          : // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            formatDevice(keyword[deviceKey], { tooltip })
  // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const getCountry = skip[countryKey]
    ? null
    : () =>
        string ? (
          short ? (
            // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            keyword[countryKey]
          ) : (
            // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            formatOption(countryOptions, keyword[countryKey])
          )
        ) : (
          <Tooltip
            tooltip={
              short
                ? // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  formatOption(countryOptions, keyword[countryKey])
                : undefined
            }
          >
            <div
              className="inline-block rounded bg-gray-400 px-1
  text-xs font-medium text-white dark:bg-gray-800"
            >
              {short
                ? // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  keyword[countryKey]
                : // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  formatOption(countryOptions, keyword[countryKey])}
            </div>
          </Tooltip>
        )
  // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const getLanguage = skip[languageKey]
    ? null
    : () =>
        string ? (
          short ? (
            // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            keyword[languageKey]
          ) : (
            // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            formatOption(languageOptions, keyword[languageKey])
          )
        ) : (
          <Tooltip
            tooltip={
              short
                ? // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  formatOption(languageOptions, keyword[languageKey])
                : undefined
            }
            className="inline-flex items-center"
          >
            <div
              className="inline-block rounded bg-gray-400 px-1
  text-xs font-medium text-white dark:bg-gray-800"
            >
              {short
                ? // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  keyword[languageKey]
                : // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  formatOption(languageOptions, keyword[languageKey])}
            </div>
          </Tooltip>
        )
  const getLocation =
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    skip[locationKey] || !keyword[locationKey]
      ? null
      : () =>
          string ? (
            keyword[locationKey]
          ) : (
            <span className="text-sm">{keyword[locationKey]}</span>
          )

  if (string) {
    return [
      getPhrase,
      getEngine,
      getDevice,
      getCountry,
      getLanguage,
      getLocation,
    ]
      .filter(Boolean)
      .map(d => d())
      .join(delimiter)
  }

  const parts = [
    getEngine,
    getDevice,
    getPhrase,
    getCountry,
    getLanguage,
    getLocation,
  ].filter(Boolean)

  return (
    <div
      className={twMerge(
        'inline-flex items-center gap-2',
        flexWrap && 'flex-wrap'
      )}
    >
      {parts.map((getItem, index) => {
        return <React.Fragment key={index}>{getItem()}</React.Fragment>
      })}
    </div>
  )
}

const reSlug = /[^a-z0-9]/gm
export function formatSlug(str = '') {
  return str
    .replace('https://', '')
    .replace('http://', '')
    .normalize('NFD')
    .toLowerCase()
    .replace(/(\..{2,})?$/gm, '') // remove TLDs from domains
    .replace(reSlug, '')
    .substring(0, 14)
}

export function formatName(str = '') {
  return str
    .replace('https://', '')
    .replace('http://', '')
    .normalize('NFD')
    .replace(/(\..{2,})?$/gm, '') // remove TLDs from domains
    .replace(reSlug, '')
}

export function formatEventTypeAction(type: any) {
  return type === 'TYPE_UPDATE'
    ? 'updated'
    : type === 'TYPE_CREATE'
    ? 'created'
    : type === 'TYPE_DELETE'
    ? 'deleted'
    : 'changed'
}

export function formatEventTypeColor(type: any) {
  return type === 'TYPE_UPDATE'
    ? 'primary'
    : type === 'TYPE_CREATE'
    ? 'success'
    : type === 'TYPE_DELETE'
    ? 'danger'
    : null
}

export function formatEventKindLabel(kind: any) {
  return kind === 'Workspace'
    ? 'Workspace'
    : kind === 'team'
    ? 'Project'
    : kind === 'brand'
    ? 'Brand'
    : kind === 'keywordSource'
    ? 'Keyword Set'
    : kind === 'oneTimePull'
    ? 'One Time Pull'
    : kind === 'segment'
    ? 'Segment'
    : kind === 'user'
    ? 'User'
    : null
}

export function formatBrandType(brandType: any) {
  return (
    brandTypeOptions.find(d => d.value == brandType)?.label ??
    `(Unknown: ${brandType})`
  )
}

export function formatBool(
  value: boolean,
  opts?: { string?: boolean; undefinedIsFalse?: boolean; yesNo?: boolean }
) {
  if (typeof value === 'undefined') {
    if (!opts?.undefinedIsFalse ?? true) {
      return ''
    }
    value = false
  }

  value = Boolean(value)

  if (opts?.string) {
    if (opts?.yesNo ?? true) {
      return value ? 'Yes' : 'No'
    }
    return value ? 'True' : 'False'
  }

  return value ? (
    <FaCheckCircle className="inline text-green-500" />
  ) : (
    <FaTimesCircle className="inline text-gray-500 opacity-20" />
  )
}

export function formatTitle(title: any) {
  const large = ['AT&T', 'Q&A', 'PPC', 'SEO', 'PAA', 'CTR', 'SERP']
  const small =
    '(a|an|and|as|at|but|by|en|for|if|in|of|on|or|the|to|v[.]?|via|vs[.]?)'
  const punct = '([!"#$%&\'()*+,./:;<=>?@[\\\\\\]^_`{|}~-]*)'

  const parts = []
  const split = /[:.;?!] |(?: |^)["Ò]/g
  let index = 0

  while (true) {
    const m = split.exec(title)

    parts.push(
      title
        .substring(index, m ? m.index : title.length)
        .replace(/\b([A-Za-z][a-z.'Õ]*)\b/g, function (all: any) {
          return /[A-Za-z]\.[A-Za-z]/.test(all) ? all : upper(all)
        })
        .replace(RegExp('\\b' + small + '\\b', 'ig'), lower)
        .replace(
          RegExp('^' + punct + small + '\\b', 'ig'),
          function (all: any, punct: any, word: any) {
            return punct + upper(word)
          }
        )
        .replace(RegExp('\\b' + small + punct + '$', 'ig'), upper)
    )

    index = split.lastIndex

    if (m) parts.push(m[0])
    else break
  }

  return parts
    .join('')
    .replace(/ V(s?)\. /gi, ' v$1. ')
    .replace(/(['Õ])S\b/gi, '$1s')
    .replace(new RegExp(`\\b(${large.join('|')})\\b`, 'gi'), function (all) {
      return all.toUpperCase()
    })

  function lower(word: any) {
    return word.toLowerCase()
  }

  function upper(word: any) {
    return word.substr(0, 1).toUpperCase() + word.substr(1)
  }
}

export function formatPostAggregation(d: MetricPostAggregation) {
  return {
    value: 'Latest',
    change: 'Change',
    best: 'Best',
    worst: 'Worst',
  }[d]
}

export function formatLimitedText(
  text: any,
  limit: any,
  opts?: { fromPercentage?: number }
): string {
  return text?.length > limit
    ? opts?.fromPercentage
      ? `${text.substring(
          0,
          Math.floor(limit * opts.fromPercentage)
        )} ... ${text.substring(
          text.length - Math.floor(limit * (1 - opts.fromPercentage))
        )}`
      : `${text.substring(0, limit)}...`
    : text
}

export function renderLimitedText(
  text: any,
  limit: any,
  opts?: { fromPercentage?: number }
): string | React.ReactNode {
  return text?.length > limit ? (
    <Tooltip
      tooltip={text}
      getTooltipProps={() => ({ className: `max-w-[95%]` })}
    >
      {opts?.fromPercentage
        ? `${text.substring(
            0,
            Math.floor(limit * opts.fromPercentage)
          )} ... ${text.substring(
            text.length - Math.floor(limit * (1 - opts.fromPercentage))
          )}`
        : `${text.substring(0, limit)}...`}
    </Tooltip>
  ) : (
    text
  )
}
