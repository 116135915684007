import * as Plot from '@observablehq/plot'
import {
  Column,
  Table as ReactTable,
  Row,
  RowData,
  Table,
  flexRender,
} from '@tanstack/react-table'
import * as React from 'react'
import {
  FaArrowDown,
  FaArrowUp,
  FaCaretDown,
  FaCheckSquare,
  FaExclamationTriangle,
  FaEyeSlash,
  FaLayerGroup,
  FaRegMinusSquare,
  FaRegSquare,
  FaSearch,
  FaStar,
  FaTimes,
  FaTimesCircle,
  FaTrashAlt,
} from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import { Counter } from '../components/Counter'
import Pager from '../components/Pager'
import { Updater, last } from '../utils'
import { formatNumber } from '../utils/Format'
import { withProps } from '../utils/withProps'
import { ClickablePlain } from './Clickable'
import { PlainInput } from './Input'
import NumberInput from './NumberInput'
import { NumberInputWrap } from './NumberInputWrap'
import Select from './Select'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { ButtonPlain } from './ButtonPlain'
import { extent } from 'd3-array'
import { ObservablePlot } from './ObservablePlot'
import { TbColumns, TbCsv, TbFilterPlus, TbJson } from 'react-icons/tb'
import { Menu, MenuItem, MenuItemCheckbox, MenuSeparator } from './Menu'
import copyToClipboard from '../utils/copyToClipboard'
import useToast from '../hooks/useToast'
import { RiFileExcel2Line } from 'react-icons/ri'
import Tooltip from './Tooltip'
import { Expander } from './ExpanderColumn'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'
import { useVirtualizer } from '@tanstack/react-virtual'
import Loader from './Loader'

//

// const ReactTableDevtoolsPanelProduction = React.lazy(() =>
//   import('@tanstack/react-table-devtools').then(d => ({
//     default: d.ReactTableDevtoolsPanel,
//   }))
// )

export const TableWrapOuter = withProps('div')<{ compact?: boolean }>(
  ({ className, compact, ...props }) => ({
    ...props,
    className: twMerge(
      'flex min-h-0 max-w-full flex-1 flex-col divide-y divide-gray-500/20 text-[.9rem]',
      // compact &&
      //   css({
      //     fontSize: '0.8rem',
      //   }),
      props.disabled && 'opacity-50',
      className
    ),
  })
)

export const TableWrapInner = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    'block max-w-full flex-1 divide-y divide-gray-500/20 overflow-x-auto overflow-y-hidden',
    className
  ),
}))

export const TableEl = withProps('table')(({ className, ...props }) => ({
  ...props,
  className: twMerge('w-full divide-y divide-gray-500/20', className),
}))

export const TableHead = withProps('thead')(({ className, ...props }) => ({
  ...props,
  className: twMerge('bg-gray-500/10', className),
}))

const RowBase = withProps('tr')(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    'table-row border-b border-gray-500/20 last:border-b-0 hover:z-[1]',
    className
  ),
}))

export const TableRow = withProps(RowBase)<{ index?: number }>(
  ({ className, index, colorEvenOdd, ...props }) => ({
    ...props,
    className: twMerge(
      typeof index === 'number'
        ? index % 2 === 1 && 'bg-gray-500/10'
        : 'even:bg-gray-500/10',
      className
    ),
  })
)

export const HeaderRow = withProps(RowBase)(({ className, ...props }) => ({
  ...props,
  className: twMerge(className),
}))

export const FilterRow = withProps(HeaderRow)(({ className, ...props }) => ({
  ...props,
  className: twMerge('rounded-none bg-gray-50 dark:bg-gray-900', className),
}))

export const TableCell = withProps('td')<{
  tight?: boolean
  compact?: boolean
}>(({ className, compact, tight, ...props }) => ({
  ...props,
  className: twMerge(
    `table-cell items-center whitespace-nowrap border-r border-dotted border-gray-500/20 p-2 text-sm leading-none ${
      tight ? 'py-1/2 w-[0.0000000001%] px-1' : 'w-[1%] px-2 py-1'
    } last:border-r-0`,
    className
  ),
}))

export const TableHeader = withProps(TableCell)(({ className, ...props }) => ({
  ...props,
  className: twMerge('select-none font-medium', className),
}))

export const Footer = withProps(TableCell)(({ className, ...props }) => ({
  ...props,
  className: twMerge('bg-gray-500/10', className),
}))

export const FilterHeader = withProps(TableHeader)(
  ({ className, ...props }) => ({
    ...props,
    className: twMerge('p-[.4rem]', className),
  })
)

export default React.forwardRef(function Table<TData extends RowData>(
  {
    table,
    beforeTable,
    afterTable,
    isLoading,
    ...rest
  }: {
    beforeTable?: any
    afterTable?: any
    isLoading?: any
    table: ReactTable<TData>
  } & React.HTMLAttributes<HTMLDivElement>,
  ref?: React.Ref<HTMLDivElement>
) {
  const metaOptions = table.options.meta!.options
  const state = table.getState()

  const rows = table.getRowModel().rows
  while (rows[0]?.getParentRow()) {
    rows.unshift(rows[0].getParentRow()!)
  }

  const prePaginatedRows = table.getPrePaginationRowModel().rows

  const fillPage = (rows: Row<TData>[]) => {
    if (metaOptions.pagination?.fill || state.pagination.pageIndex > 0) {
      return [...new Array(state.pagination.pageSize)].map((d, i) => rows[i])
    }
    return rows
  }

  let hasFooters = false

  const selectChildrenMode = metaOptions?.selectChildrenMode ?? 'all'

  const isVirtualized = metaOptions.virtualize

  const showPagination =
    !isVirtualized &&
    (metaOptions.pagination?.show ?? !!table.options.data.length)

  const showCounter = metaOptions.counter?.show ?? !!table.options.data.length
  const showToolbar = !!table.options.data?.length

  const [scrollEl, setScrollEl] = React.useState<HTMLDivElement>(null!)

  const virtualizer = useVirtualizer({
    count: prePaginatedRows.length,
    estimateSize: React.useCallback(() => 30, []),
    getScrollElement: () => scrollEl,
    overscan: 10,
  })

  const tableWrapProps = metaOptions.getTableWrapProps?.()

  const tableWrapRef = el => {
    setScrollEl(el)
    tableWrapProps?.ref?.(el)
  }

  return (
    <TableWrapOuter
      {...{
        ref,
        compact: metaOptions.compact,
        ...rest,
      }}
    >
      {showToolbar ? <TableToolbar table={table} /> : null}
      {beforeTable ? <div>{beforeTable}</div> : null}
      <TableWrapInner
        {...tableWrapProps}
        className={twMerge(isVirtualized && 'overflow-y-auto')}
        ref={tableWrapRef}
      >
        <TableEl>
          <TableHead className={twMerge(isVirtualized && 'sticky top-0')}>
            {isVirtualized ? (
              <div className="pointer-events-none absolute inset-0 shadow-lg" />
            ) : null}
            {table.getHeaderGroups().map(headerGroup => {
              return (
                <HeaderRow key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    const { column } = header

                    if (header.column.columnDef.footer) {
                      hasFooters = true
                    }

                    const tableHeaderProps =
                      column.columnDef.meta?.getHeaderProps?.({
                        header,
                        column,
                        table,
                      })

                    const showExpander =
                      column.getIsGrouped() || column.columnDef.meta?.expander

                    const showCheckbox = column.columnDef.meta?.checkbox

                    return (
                      <TableHeader
                        {...{
                          key: header.id,
                          tight:
                            header.column.id === 'spacer'
                              ? false
                              : metaOptions.tight ??
                                column.columnDef.meta?.tight,
                          compact: metaOptions.compact,
                          colSpan: header.colSpan,
                          ...tableHeaderProps,
                          className: twMerge(
                            'p-0',
                            isVirtualized && 'bg-gray-50 dark:bg-gray-800',
                            tableHeaderProps?.className
                          ),
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div className={twMerge(`flex items-center`)}>
                            {showExpander ? (
                              <label
                                className={twMerge(
                                  'flex cursor-pointer items-center text-center',
                                  metaOptions.compact
                                    ? 'px-1 py-1'
                                    : 'px-1.5 py-2'
                                )}
                                onClick={() => table.toggleAllRowsExpanded()}
                              >
                                <Expander
                                  expanded={table.getIsAllRowsExpanded()}
                                />
                              </label>
                            ) : null}
                            {showCheckbox ? (
                              <label
                                className={twMerge(
                                  'flex cursor-pointer items-center text-center',
                                  metaOptions.compact
                                    ? 'px-1 py-1'
                                    : 'px-1.5 py-2'
                                )}
                              >
                                <IndeterminateCheckbox
                                  {...{
                                    checked: table.getIsAllRowsSelected(),
                                    indeterminate:
                                      table.getIsSomeRowsSelected(),
                                    onChange:
                                      table.getToggleAllRowsSelectedHandler(),
                                  }}
                                />
                              </label>
                            ) : null}
                            <div
                              className={twMerge(
                                header.column.getCanSort() && `cursor-pointer`,
                                'flex flex-1',
                                metaOptions.compact
                                  ? 'px-1 py-1'
                                  : 'px-1.5 py-2'
                              )}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                            {column.getIsSorted() ? (
                              <span
                                className={twMerge(
                                  `flex cursor-pointer items-center gap-1`
                                )}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {column.getIsSorted() === 'desc' ? (
                                  <FaArrowDown className="inline text-green-700 dark:text-green-500" />
                                ) : (
                                  <FaArrowUp className="inline text-blue-600 dark:text-blue-500" />
                                )}

                                {column.getSortIndex() > 0 ? (
                                  <sup>{column.getSortIndex() + 1}</sup>
                                ) : null}
                              </span>
                            ) : null}
                          </div>
                        )}
                      </TableHeader>
                    )
                  })}
                </HeaderRow>
              )
            })}
          </TableHead>
          <tbody>
            {isVirtualized ? (
              <tr
                style={{
                  height: virtualizer.getVirtualItems()[0]?.start ?? 0,
                }}
              >
                <td className="p-0" />
              </tr>
            ) : null}
            {!prePaginatedRows.length ? (
              <tr>
                <td
                  className="p-2 italic opacity-50"
                  colSpan={table.getVisibleLeafColumns().length}
                >
                  No {metaOptions.counter?.showingName || 'rows'} found.
                </td>
              </tr>
            ) : (
              (isVirtualized
                ? virtualizer.getVirtualItems().map(item => {
                    return [prePaginatedRows[item.index], item] as const
                  })
                : (rows.length ? fillPage(rows) : rows).map(
                    row => [row, undefined] as const
                  )
              ).map(([row, virtualItem], i) => {
                i = virtualItem ? virtualItem.index : i

                if (!row) {
                  return (
                    <TableRow key={'empty' + i} index={i}>
                      {table
                        .getCoreRowModel()
                        .rows[0]?.getVisibleCells()
                        .map((cell, i) => (
                          <TableCell
                            key={i}
                            tight={
                              metaOptions.tight ??
                              cell.column.columnDef.meta?.tight
                            }
                            compact={metaOptions.compact}
                            className="p-0"
                          >
                            <div
                              className={twMerge(
                                metaOptions.tight ? 'p-1.5' : 'p-2'
                              )}
                            >
                              &nbsp;
                            </div>
                          </TableCell>
                        )) ?? null}
                    </TableRow>
                  )
                }

                return (
                  <React.Fragment key={row.id}>
                    <TableRow
                      index={i}
                      {...metaOptions.getRowProps?.({ row, table })}
                      // className={twMerge(isParentRowAnchor && 'opacity-50')}
                    >
                      {row.getVisibleCells().map(cell => {
                        const columnHasExpander =
                          (row.getIsGrouped() && cell.getIsGrouped()) ||
                          cell.column.columnDef.meta?.expander

                        const cellHasExpander =
                          columnHasExpander &&
                          (row.getCanExpand() ?? row.subRows.length)

                        const showSubRowCount = cellHasExpander

                        const showCheckbox =
                          cell.column.columnDef.meta?.checkbox

                        const cellProps =
                          cell.column.columnDef.meta?.getCellProps?.(
                            cell.getContext()
                          )

                        return (
                          <TableCell
                            {...{
                              key: cell.id,
                              tight:
                                cell.column.id === 'spacer'
                                  ? false
                                  : metaOptions.tight ??
                                    cell.column.columnDef.meta?.tight,
                              compact: metaOptions.compact,
                              className: 'p-0',
                              // style: {
                              //   height: virtualItem?.size,
                              // },
                            }}
                          >
                            <div className="flex w-full items-center">
                              {columnHasExpander ? (
                                <label
                                  className={twMerge(
                                    'flex cursor-pointer items-center gap-1 text-center',
                                    cellHasExpander
                                      ? 'pointer-events-auto opacity-100'
                                      : 'pointer-events-none opacity-0',
                                    metaOptions.compact ? 'p-1' : 'p-1.5'
                                  )}
                                  style={{
                                    marginLeft: row.depth * 1.5 + 'rem',
                                  }}
                                  onClick={row.getToggleExpandedHandler()}
                                >
                                  <Expander expanded={row.getIsExpanded()} />
                                </label>
                              ) : null}
                              {showCheckbox ? (
                                <label
                                  className={twMerge(
                                    metaOptions.compact ? 'p-1' : 'p-1.5'
                                  )}
                                >
                                  <IndeterminateCheckbox
                                    {...{
                                      // checked: (
                                      //   ['subRows', 'all'] as const
                                      // ).includes(selectChildrenMode)
                                      //   ? row.getIsAllSubRowsSelected()
                                      //   : row.getIsSelected(),
                                      checked: row.getIsSelected(),
                                      indeterminate:
                                        selectChildrenMode === 'groupedRows'
                                          ? false
                                          : row.getIsSomeSelected(),
                                      onChange: e => {
                                        row.toggleSelected(
                                          e.currentTarget.checked,
                                          {
                                            selectChildren: (
                                              ['subRows', 'all'] as const
                                            ).includes(selectChildrenMode),
                                          }
                                        )
                                      },
                                    }}
                                  />
                                </label>
                              ) : null}
                              <div
                                {...cellProps}
                                className={twMerge(
                                  !showSubRowCount && 'flex-1',
                                  metaOptions.tight ? 'p-1.5' : 'p-2',
                                  cellProps?.className
                                )}
                              >
                                {row.getIsGrouped() && cell.getIsAggregated()
                                  ? // If the cell is aggregated, use the Aggregated
                                    // renderer for cell
                                    flexRender(
                                      // cell.column.columnDef.aggregatedCell ??
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )
                                  : cell.getIsPlaceholder()
                                  ? null
                                  : flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                              </div>
                              {showSubRowCount ? (
                                <span className="mx-1 flex flex-1 items-center text-sm">
                                  {selectChildrenMode === 'groupedRows' ? (
                                    <Tooltip tooltip="Toggle Children Selected">
                                      <label
                                        className="flex cursor-pointer items-center gap-[2px]"
                                        onClick={() => {
                                          if (row.getIsAllSubRowsSelected()) {
                                            row.subRows.forEach(r => {
                                              r.toggleSelected(false)
                                            })
                                          } else {
                                            row.subRows.forEach(r => {
                                              r.toggleSelected(true)
                                            })
                                          }
                                        }}
                                      >
                                        {row.getIsAllSubRowsSelected() ? (
                                          <FaCheckSquare className="text-blue-500" />
                                        ) : row.getIsSomeSelected() ? (
                                          <FaRegMinusSquare className="text-gray-500" />
                                        ) : (
                                          <FaRegSquare className="opacity-50" />
                                        )}
                                        <span className="text-xs">
                                          {row.subRows.length}
                                        </span>
                                      </label>
                                    </Tooltip>
                                  ) : (
                                    <span className="text-xs opacity-50">
                                      ({row.subRows.length})
                                    </span>
                                  )}
                                </span>
                              ) : null}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                    {metaOptions.subComponent &&
                    !row.subRows?.length &&
                    row.getIsExpanded() ? (
                      <TableRow index={1}>
                        <td
                          colSpan={row.getVisibleCells().length}
                          className="overflow-x-auto"
                        >
                          {flexRender(metaOptions.subComponent, {
                            table,
                            row,
                          })}
                        </td>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                )
              })
            )}
            {isVirtualized ? (
              <tr
                className="p-0"
                style={{
                  height:
                    virtualizer.getTotalSize() -
                    ((last(virtualizer.getVirtualItems())?.start ?? 0) +
                      (last(virtualizer.getVirtualItems())?.size ?? 0)),
                }}
              >
                <td />
              </tr>
            ) : null}
          </tbody>
          {table.options.data.length && hasFooters ? (
            <tfoot>
              <HeaderRow className="border-t-2 border-gray-500/20">
                {last(table.getHeaderGroups())?.headers.map(header => (
                  <Footer
                    {...{
                      key: header.id,
                      compact: metaOptions.compact,
                      tight:
                        metaOptions.tight ??
                        header.column.columnDef.meta?.tight,
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.footer,
                      header.getContext()
                    )}
                  </Footer>
                ))}
              </HeaderRow>
            </tfoot>
          ) : null}
        </TableEl>
      </TableWrapInner>
      {afterTable ? <div>{afterTable}</div> : null}
      {showPagination || showCounter ? (
        <div
          className={twMerge(
            'flex flex-wrap items-center justify-between gap-2',
            metaOptions.compact ? 'p-1' : 'p-2'
          )}
        >
          {showPagination ? (
            <Pager
              {...{
                canNextPage: table.getCanNextPage(),
                canPreviousPage: table.getCanPreviousPage(),
                offset: state.pagination.pageIndex,
                setOffset: val => table.setPageIndex(val),
                size: prePaginatedRows.length,
                limit: state.pagination.pageSize,
                setLimit: table.setPageSize,
              }}
            />
          ) : null}
          {showCounter ? (
            <Counter
              {...{
                // isLoading: isLoading || metaOptions.isLoading,
                count: isVirtualized ? prePaginatedRows.length : rows.length,
                totalCount: prePaginatedRows.length,
                totalName: metaOptions.counter?.totalName,
                showingName: metaOptions.counter?.showingName,
              }}
            />
          ) : null}
        </div>
      ) : null}
      {/* {showDevtools ? (
        <React.Suspense fallback="">
          <ReactTableDevtoolsPanelProduction
            table={table}
            setIsOpen={() => void 0}
          />
        </React.Suspense>
      ) : null} */}
    </TableWrapOuter>
  )
})

export function TableToolbar({ table }: { table: ReactTable<any> }) {
  // const [showGrouping, setShowGrouping] = React.useState(
  //   table.getState().grouping.length > 0
  // )

  const showFilters = table.options.meta?.options.showFilters ?? true
  const showGroupBy = table.options.meta?.options.showGroupBy ?? true

  const [temporaryFilterVisibility, setTemporaryFilterVisibility] =
    React.useState<string[]>([])

  const allLeafColumns = table.getAllLeafColumns()

  const filterableColumns = React.useMemo(
    () => allLeafColumns.filter(d => d.getCanFilter()),
    [allLeafColumns]
  )

  const visibleColumns = React.useMemo(() => {
    return filterableColumns.filter(
      d => d.getIsFiltered() || temporaryFilterVisibility.includes(d.id)
    )
  }, [filterableColumns, temporaryFilterVisibility])

  const availableColumns = React.useMemo(() => {
    return filterableColumns.filter(
      d => !d.getIsFiltered() && !temporaryFilterVisibility.includes(d.id)
    )
  }, [filterableColumns, temporaryFilterVisibility])

  const availableColumnOptions = React.useMemo(() => {
    return availableColumns.map(d => ({
      label: typeof d.columnDef.header === 'string' ? d.columnDef.header : d.id,
      value: d.id,
    }))
  }, [availableColumns])

  const quickFilterOptions = React.useMemo(() => {
    return availableColumns.filter(d => d.columnDef.meta?.showQuickFilter)
  }, [availableColumns])

  const groupingColumns = React.useMemo(() => {
    return allLeafColumns.filter(d => d.accessorFn)
  }, [allLeafColumns])

  const groupingOptions = React.useMemo(() => {
    return groupingColumns.map(d => ({
      label: typeof d.columnDef.header === 'string' ? d.columnDef.header : d.id,
      value: d.id,
    }))
  }, [groupingColumns])

  const toast = useToast()

  function getTextValue(value: any) {
    let textValue: string

    if (value === undefined) {
      textValue = ''
    } else if (value === null) {
      textValue = ''
    } else if (value instanceof Date) {
      textValue = value.toLocaleString()
    } else if (typeof value === 'object') {
      textValue = JSON.stringify(value)
    } else {
      textValue = `${value}`
    }

    return textValue
  }

  function getCsvTextValue(value: any) {
    value = getTextValue(value)
    value = value.replace(/"/g, '""')
    if (value.search(/("|,|\n)/g) >= 0) value = '"' + value + '"'
    return value
  }

  return (
    <form
      className="space-y-2 bg-gray-50/50 p-2 dark:bg-gray-900"
      onSubmit={e => {
        e.preventDefault()
        // submitFilters()
      }}
    >
      {/* {isLoading ? <Loader /> : null} */}
      <div className="flex flex-wrap items-center gap-2">
        <Menu
          button={
            <div className="p-1">
              <BsThreeDotsVertical className="text-lg" />
            </div>
          }
        >
          {/* <button
            className="flex items-center gap-2 px-2 py-1 text-sm font-bold hover:bg-blue-500 hover:text-white"
            onClick={() => {
              setShowGrouping(prev => !prev)
            }}
          >
            <ImMakeGroup /> {showGrouping ? 'Hide' : 'Show'} Row Grouping
          </button> */}

          <MenuItem
            onClick={() => {
              debugger
              console.log(table.getPrePaginationRowModel())

              copyToClipboard(
                [
                  table
                    .getAllColumns()
                    .filter(d => d.accessorFn)
                    .map(d => {
                      return getTextValue(
                        typeof d.columnDef.header === 'string'
                          ? d.columnDef.header
                          : d.id
                      )
                    })
                    .join('\t'),
                  ...table.getPrePaginationRowModel().flatRows.map(d =>
                    d
                      .getAllCells()
                      .filter(d => d.column.accessorFn)
                      .map(d => {
                        return getTextValue(d.getValue())
                      })
                      .join('\t')
                  ),
                ].join('\n')
              )
              toast({
                color: 'green-500',
                message: 'Copied to clipboard',
              })
            }}
          >
            <RiFileExcel2Line /> Copy as Excel
          </MenuItem>
          <MenuItem
            onClick={() => {
              copyToClipboard(
                [
                  table
                    .getAllColumns()
                    .filter(d => d.accessorFn)
                    .map(d => {
                      return getCsvTextValue(
                        typeof d.columnDef.header === 'string'
                          ? d.columnDef.header
                          : d.id
                      )
                    })
                    .join(','),
                  ...table.getPrePaginationRowModel().flatRows.map(d =>
                    d
                      .getAllCells()
                      .filter(d => d.column.accessorFn)
                      .map(d => {
                        const textValue = getCsvTextValue(d.getValue())
                        return textValue
                      })
                      .join(',')
                  ),
                ].join('\n')
              )
              toast({
                color: 'green-500',
                message: 'Copied to clipboard',
              })
            }}
          >
            <TbCsv /> Copy as CSV
          </MenuItem>
          <MenuItem
            onClick={() => {
              const headers =
                table
                  .getRowModel()
                  .flatRows[0]?.getAllCells()
                  .filter(d => d.column.accessorFn)
                  .map(d =>
                    d.column.columnDef.header === 'string'
                      ? d.column.columnDef
                      : d.column.id
                  ) ?? []

              copyToClipboard(
                JSON.stringify(
                  [
                    ...table.getPrePaginationRowModel().flatRows.map(d => {
                      return Object.fromEntries(
                        d
                          .getAllCells()
                          .filter(d => d.column.accessorFn)
                          .map((cell, i) => [headers[i], cell.getValue()])
                      )
                    }),
                  ],
                  (key, value) =>
                    typeof value === 'bigint' ? `${value}` : value,
                  2
                )
              )
              toast({
                color: 'green-500',
                message: 'Copied to clipboard',
              })
            }}
          >
            <TbJson /> Copy as JSON
          </MenuItem>
        </Menu>
        {!table.options.meta?.options.isControlled && showFilters ? (
          <div className="flex items-center rounded-md bg-gray-500/10 text-black dark:text-white">
            <div className='className="flex text-[.7rem]" items-center px-2 py-1'>
              <FaSearch />
            </div>
            <PlainInput
              placeholder="Search Table..."
              value={table.getState().globalFilter ?? ''}
              onChange={e => {
                table.setGlobalFilter(e.currentTarget.value)
              }}
              className="bg-transparent px-0 py-1 text-sm focus:bg-transparent"
              debounce={500}
            />
            {table.getState().globalFilter ? (
              <button
                type="button"
                className={twMerge(
                  `flex items-center gap-1 p-1 px-2 text-[.7rem] hover:text-red-500`
                )}
                onClick={() => table.setGlobalFilter('')}
              >
                <FaTimes />
              </button>
            ) : null}
          </div>
        ) : null}
        <Tooltip tooltip="Column Visibility">
          <Menu
            button={
              <ButtonPlain
                className="bg-gray-500/20 px-2 py-1 text-black hover:bg-gray-500/10 dark:text-white"
                asChild
              >
                <div>
                  <TbColumns className="text-lg" />
                </div>
              </ButtonPlain>
            }
          >
            <MenuItemCheckbox
              checked={table.getIsAllColumnsVisible()}
              indeterminate={table.getIsSomeColumnsVisible()}
              onChange={_e => {
                table.setColumnVisibility(_prev =>
                  Object.fromEntries(
                    allLeafColumns.map(d => [
                      d.id,
                      !table.getIsSomeColumnsVisible(),
                    ])
                  )
                )
              }}
            >
              Toggle All
            </MenuItemCheckbox>
            <MenuSeparator />
            {allLeafColumns.map(column => {
              return (
                <MenuItemCheckbox
                  key={column.id}
                  checked={column.getIsVisible()}
                  onChange={e => {
                    column.toggleVisibility(e.currentTarget.checked)
                  }}
                  disabled={
                    column.columnDef.meta?.expander ||
                    column.columnDef.meta?.checkbox
                  }
                >
                  {typeof column.columnDef.header === 'string'
                    ? column.columnDef.header
                    : column.id}
                </MenuItemCheckbox>
              )
            })}
          </Menu>
        </Tooltip>
        {!table.options.meta?.options.isControlled ? (
          <>
            {showGroupBy ? (
              <Tooltip tooltip="Group By">
                <Select
                  multi
                  options={groupingOptions}
                  value={table.getState().grouping}
                  onChange={values => {
                    table.setGrouping(values)
                    table.setColumnVisibility(prev => ({
                      ...prev,
                      expander: !!values.length,
                    }))
                  }}
                  children={({ onClick }) => {
                    return (
                      <ButtonPlain
                        className={twMerge(
                          'flex items-center gap-1 bg-gray-500/20 text-xs text-black hover:bg-gray-500/10 dark:text-white',
                          table.getState().grouping?.length &&
                            '!bg-blue-500 !text-white hover:bg-blue-400'
                        )}
                        onClick={onClick}
                      >
                        <FaLayerGroup className="text-sm" />
                        {table.getState().grouping.length ? (
                          <>
                            <div>Group By</div>
                            <FaCaretDown />
                          </>
                        ) : null}
                      </ButtonPlain>
                    )
                  }}
                />
              </Tooltip>
            ) : null}
            {showFilters ? (
              <>
                <Select
                  options={availableColumnOptions}
                  onChange={id => {
                    setTemporaryFilterVisibility(prev => [...prev, id])
                  }}
                  children={({ onClick }) => {
                    return (
                      <ButtonPlain
                        className="flex items-center gap-1 bg-gray-500/20 text-xs text-black hover:bg-gray-500/10 dark:text-white"
                        onClick={onClick}
                      >
                        <TbFilterPlus className="text-base opacity-50" /> Filter
                      </ButtonPlain>
                    )
                  }}
                />
                {quickFilterOptions.map(column => {
                  return (
                    <ButtonPlain
                      key={column.id}
                      className={twMerge(
                        'z-0 flex items-center bg-gray-500/20 p-0 text-xs text-black shadow-md shadow-black/10',
                        'hover:relative hover:z-10 hover:bg-gray-500/10 dark:text-white',
                        ''
                      )}
                      onClick={() => {
                        setTemporaryFilterVisibility(prev => [
                          ...prev,
                          column.id,
                        ])
                      }}
                    >
                      <Tooltip
                        className="flex items-center gap-1 px-2 py-1"
                        tooltip={`Filter by ${
                          typeof column.columnDef.header === 'string'
                            ? column.columnDef.header
                            : column.id
                        }`}
                      >
                        <FaStar className="text-sm opacity-50" />
                        {typeof column.columnDef.header === 'string'
                          ? column.columnDef.header
                          : column.id}
                      </Tooltip>
                      {(() => {
                        if (
                          column.columnDef.filterFn !== 'arrIncludes' &&
                          column.columnDef.filterFn !== 'arrIncludesAll' &&
                          column.columnDef.filterFn !== 'arrIncludesSome'
                        ) {
                          return null
                        }

                        const facets = [
                          ...column.getFacetedUniqueValues().entries(),
                        ].slice(0, 20)

                        if (
                          facets.length <= 1 ||
                          facets.every(d => d[1] === facets[0]?.[1])
                        ) {
                          return null
                        }

                        const domain = extent(facets, d => d[1]) as [
                          number,
                          number
                        ]

                        const getBetween =
                          <T,>(items: [T, T]) =>
                          (d: [string, number]): T => {
                            const between = (() => {
                              if (
                                column.columnDef.filterFn === 'inNumberRange'
                              ) {
                                const [min, max] = column.getFilterValue() as [
                                  number,
                                  number
                                ]

                                const logicalMin = d[1]
                                const logicalMax =
                                  last(facets)?.[1] === d[1] ? Infinity : d[1]

                                return (
                                  logicalMin >=
                                    ((min as number) ?? -Infinity) &&
                                  logicalMax <= ((max as number) ?? Infinity)
                                )
                              }

                              if (
                                column.columnDef.filterFn ===
                                  'arrIncludesSome' ||
                                column.columnDef.filterFn === 'arrIncludesAll'
                              ) {
                                const value =
                                  column.getFilterValue() as string[]
                                return value?.includes(d[0])
                              }

                              return false
                            })()

                            return between ? items[0] : items[1]
                          }

                        return (
                          <div
                            className="h-[20px] self-end pr-1 pt-px opacity-20 transition-opacity hover:opacity-100"
                            style={{
                              width: Math.min(facets.length * 10, 80),
                            }}
                          >
                            <ObservablePlot<[string, number]>
                              className="z-10 h-full w-full p-0 [font-weight:initial] [text-transform:initial]"
                              options={{
                                inset: 0,
                                padding: 0,
                                x: {
                                  axis: null,
                                  label:
                                    typeof column.columnDef.header === 'string'
                                      ? column.columnDef.header
                                      : column.id,
                                  type: 'band',
                                  domain: facets.map(d => d[0]),
                                  paddingInner: 0.2,
                                  paddingOuter: 0,
                                  inset: 0,
                                },
                                y: {
                                  axis: null,
                                  label:
                                    table.options.meta?.options.counter
                                      ?.showingName ?? 'Items',
                                },
                                marks: [
                                  Plot.barY(facets, {
                                    x: d => d[0],
                                    y: d =>
                                      Math.max(
                                        Number(d[1]),
                                        domain[0] +
                                          (domain[1] - domain[0]) * 0.1
                                      ),
                                    fill: getBetween([
                                      '#0C6A8A',
                                      'currentColor',
                                    ]),
                                  }),
                                  Plot.barY(facets, {
                                    x: d => d[0],
                                    y: d => Number(d[1]),
                                    fill: getBetween([
                                      '#0C6A8A',
                                      'currentColor',
                                    ]),
                                  }),
                                  Plot.crosshairX(facets, {
                                    x: d => d[0],
                                    y: d => Number(d[1]),
                                    textStrokeWidth: 10,
                                    // ruleStrokeOpacity: getBetween([1, 0.5]),
                                  }),
                                ],
                              }}
                              onTipClick={(facet, e) => {
                                setTemporaryFilterVisibility(prev => [
                                  ...prev,
                                  column.id,
                                ])

                                if (
                                  column.columnDef.filterFn === 'inNumberRange'
                                ) {
                                  const logicalMin = facet[1]
                                  const logicalMax =
                                    facet === last(facets)
                                      ? undefined
                                      : facet[1]

                                  if (e.shiftKey) {
                                    column.setFilterValue((prev: any) => [
                                      prev[0],
                                      logicalMax,
                                    ])
                                  } else {
                                    column.setFilterValue((prev: any) => {
                                      if (
                                        prev?.[0] === logicalMin ||
                                        prev?.[1] === logicalMax
                                      ) {
                                        return [undefined, undefined]
                                      }
                                      return [logicalMin, logicalMax]
                                    })
                                  }
                                } else if (
                                  column.columnDef.filterFn ===
                                    'arrIncludesAll' ||
                                  column.columnDef.filterFn === 'equals' ||
                                  column.columnDef.filterFn ===
                                    'arrIncludesSome'
                                ) {
                                  const options = [
                                    ...column
                                      .getFacetedUniqueValues()
                                      .entries(),
                                  ]

                                  if (e.shiftKey) {
                                    column.setFilterValue((prev: any) => {
                                      const startId = prev[0] as
                                        | undefined
                                        | string

                                      if (!startId) {
                                        return options
                                          .slice(
                                            0,
                                            options.findIndex(
                                              d => d[1] === facet[1]
                                            )
                                          )
                                          .map(d => d[0])
                                      }

                                      return options
                                        .slice(
                                          options.findIndex(
                                            d => d[0] === startId
                                          ),
                                          (options.findIndex(
                                            d => d[1] === facet[1]
                                          ) ?? -1) + 1
                                        )
                                        .map(d => d[0])
                                    })
                                  } else if (e.metaKey || e.ctrlKey) {
                                    column.setFilterValue((prev: any) => {
                                      const prevIds = (prev ?? []) as string[]
                                      const found = prevIds.includes(facet[0])

                                      return found
                                        ? prevIds.filter(d => d !== facet[0])
                                        : [...prevIds, facet[0]]
                                    })
                                  } else {
                                    column.setFilterValue((prev: any) => {
                                      const prevIds = (prev ?? []) as string[]
                                      const found = prevIds.includes(facet[0])

                                      if (found) {
                                        return prevIds.filter(
                                          d => d !== facet[0]
                                        )
                                      }

                                      return [facet[0]]
                                    })
                                  }
                                }
                              }}
                            />
                          </div>
                        )
                      })()}
                    </ButtonPlain>
                  )
                })}
              </>
            ) : null}
          </>
        ) : null}
      </div>
      {showFilters && visibleColumns.length ? (
        <div className="flex flex-wrap gap-2 text-xs">
          {visibleColumns.map(column => {
            return (
              <Filter
                key={column.id}
                table={table}
                column={column}
                setTemporaryFilterVisibility={setTemporaryFilterVisibility}
                temporaryFilterVisibility={temporaryFilterVisibility}
              />
            )
          })}
        </div>
      ) : null}
      {showFilters && filterableColumns.find(d => d.getIsFiltered()) ? (
        <div className="flex flex-wrap gap-2">
          <ButtonPlain
            className="flex items-center gap-1 bg-gray-200 text-xs hover:bg-red-500 dark:bg-gray-600"
            onClick={() => {
              table.resetGlobalFilter()
              table.resetColumnFilters()
            }}
          >
            <FaTrashAlt /> Clear All
          </ButtonPlain>
        </div>
      ) : null}
    </form>
  )
}

function Filter({
  column,
  table,
  temporaryFilterVisibility,
  setTemporaryFilterVisibility,
}: {
  table: Table<any>
  column: Column<any, any>
  temporaryFilterVisibility: string[]
  setTemporaryFilterVisibility: (updater: Updater<string[]>) => void
}) {
  // const [isChartHovered, setIsChartHovered] = React.useState(false)

  const isActive = column.getIsFiltered()
  // (Array.isArray(filtersDraft?.[filter.keys[0]])
  //   ? (filtersDraft?.[filter.keys[0]] as any).length
  //   : filtersDraft?.[filter.keys[0]] !== undefined) ||
  // (Array.isArray(filtersDraft?.[filter.keys[1]])
  //   ? (filtersDraft?.[filter.keys[1]] as any).length
  //   : filtersDraft?.[filter.keys[1]] !== undefined)

  return (
    <div
      key={column.id}
      className={twMerge(
        'flex flex-col justify-between rounded-lg border-2 border-transparent bg-white shadow-md shadow-black/5 dark:bg-gray-800',
        isActive && 'border-blue-500'
      )}
    >
      <div className="flex justify-between gap-1 px-1">
        <div className="space-between flex items-center gap-1">
          <div
            className={twMerge(
              'text-xs font-bold uppercase opacity-60',
              isActive && 'font-black text-blue-400 opacity-100 dark:text-white'
            )}
          >
            {typeof column.columnDef.header === 'string'
              ? column.columnDef.header
              : column.id}
          </div>
          {column.columnDef.filterFn === 'arrIncludesAll' ||
          column.columnDef.filterFn === 'equals' ||
          column.columnDef.filterFn === 'arrIncludesSome' ||
          column.columnDef.filterFn === 'fuzzy' ||
          column.columnDef.filterFn === 'includesString' ? (
            <div className="text-[.6rem] font-bold opacity-30">
              ({formatNumber(column.getFacetedUniqueValues()?.size ?? NaN)})
            </div>
          ) : null}
        </div>
        {isActive ? (
          <ClickablePlain
            onClick={() => {
              table.setColumnFilters(prev =>
                prev.filter(d => d.id !== column.id)
              )
            }}
            className="-mr-1 p-1 text-red-500 opacity-40 hover:opacity-100"
          >
            <FaTimesCircle />
          </ClickablePlain>
        ) : (
          <ClickablePlain
            onClick={() => {
              setTemporaryFilterVisibility(prev =>
                prev.filter(d => d !== column.id)
              )
            }}
            className="-mr-1 p-1 text-gray-500 opacity-70 hover:opacity-100"
          >
            <FaEyeSlash className="text-sm" />
          </ClickablePlain>
        )}
      </div>
      {(() => {
        if (
          column.columnDef.filterFn !== 'arrIncludes' &&
          column.columnDef.filterFn !== 'arrIncludesAll' &&
          column.columnDef.filterFn !== 'arrIncludesSome'
        ) {
          return
        }

        const facets = [...column.getFacetedUniqueValues().entries()].slice(
          0,
          20
        )

        if (facets.length <= 1 || facets.every(d => d[1] === facets[0]?.[1])) {
          return null
        }

        const domain = extent(facets, d => d[1]) as [number, number]

        const getBetween =
          <T,>(items: [T, T]) =>
          (d: [string, number]): T => {
            const between = (() => {
              if (column.columnDef.filterFn === 'inNumberRange') {
                const [min, max] = column.getFilterValue() as [number, number]

                const logicalMin = d[1]
                const logicalMax = last(facets)?.[1] === d[1] ? Infinity : d[1]

                return (
                  logicalMin >= ((min as number) ?? -Infinity) &&
                  logicalMax <= ((max as number) ?? Infinity)
                )
              }

              if (
                column.columnDef.filterFn === 'arrIncludesSome' ||
                column.columnDef.filterFn === 'arrIncludesAll'
              ) {
                const value = column.getFilterValue() as string[]
                return value?.includes(d[0])
              }

              return false
            })()

            return between ? items[0] : items[1]
          }

        return (
          <ObservablePlot<[string, number]>
            className="z-10 h-[20px] w-full cursor-pointer"
            // onPointerEnter={() => {
            //   setIsChartHovered(true)
            // }}
            // onPointerLeave={() => {
            //   setIsChartHovered(false)
            // }}
            options={{
              x: {
                axis: null,
                label:
                  typeof column.columnDef.header === 'string'
                    ? column.columnDef.header
                    : column.id,
                type: 'band',
                domain: facets.map(d => d[0]),
              },
              y: {
                axis: null,
                label:
                  table.options.meta?.options.counter?.showingName ?? 'Items',
              },
              marks: [
                // Plot.ruleY([0], {
                //   opacity: 0.2,
                // }),
                Plot.barY(facets, {
                  x: d => d[0],
                  y: d =>
                    Math.max(
                      Number(d[1]),
                      domain[0] + (domain[1] - domain[0]) * 0.1
                    ),
                  fill: getBetween(['#0C6A8A', '#bbb']),
                }),
                Plot.barY(facets, {
                  x: d => d[0],
                  y: d => Number(d[1]),
                  fill: getBetween(['#0C6A8A', '#bbb']),
                }),
                Plot.text(facets, {
                  x: d => d[0],
                  y: d => Number(d[1]),
                  rotate: 90,
                  fontSize: 8,
                  textAnchor: 'start',
                  dy: 4,
                  clip: true,
                  fillOpacity: getBetween([1, 0.4]),
                  fill: getBetween(['white', 'black']),
                  text: d => d[0],
                }),
                Plot.tip(
                  facets,
                  Plot.pointerX({
                    x: d => d[0],
                    y: d => Number(d[1]),
                  })
                ),
              ],
            }}
            onTipClick={(facet, e) => {
              // setIsChartHovered(false)

              if (column.columnDef.filterFn === 'inNumberRange') {
                const logicalMin = facet[1]
                const logicalMax = facet === last(facets) ? undefined : facet[1]

                if (e.shiftKey) {
                  column.setFilterValue((prev: any) => [prev[0], logicalMax])
                } else {
                  column.setFilterValue((prev: any) => {
                    if (prev?.[0] === logicalMin || prev?.[1] === logicalMax) {
                      return [undefined, undefined]
                    }
                    return [logicalMin, logicalMax]
                  })
                }
              } else if (
                column.columnDef.filterFn === 'arrIncludesAll' ||
                column.columnDef.filterFn === 'equals' ||
                column.columnDef.filterFn === 'arrIncludesSome'
              ) {
                const options = [...column.getFacetedUniqueValues().entries()]

                if (e.shiftKey) {
                  column.setFilterValue((prev: any) => {
                    const startId = prev[0] as undefined | string

                    if (!startId) {
                      return options
                        .slice(
                          0,
                          options.findIndex(d => d[1] === facet[1])
                        )
                        .map(d => d[0])
                    }

                    return options
                      .slice(
                        options.findIndex(d => d[0] === startId),
                        (options.findIndex(d => d[1] === facet[1]) ?? -1) + 1
                      )
                      .map(d => d[0])
                  })
                } else if (e.metaKey || e.ctrlKey) {
                  column.setFilterValue((prev: any) => {
                    const prevIds = (prev ?? []) as string[]
                    const found = prevIds.includes(facet[0])

                    return found
                      ? prevIds.filter(d => d !== facet[0])
                      : [...prevIds, facet[0]]
                  })
                } else {
                  column.setFilterValue((prev: any) => {
                    const prevIds = (prev ?? []) as string[]
                    const found = prevIds.includes(facet[0])

                    if (found) {
                      return prevIds.filter(d => d !== facet[0])
                    }

                    return [facet[0]]
                  })
                }
              }
            }}
          />
        )
        // }
      })()}
      <div
        className={twMerge(
          'border-t border-gray-500/20 transition-opacity duration-200'
          // isChartHovered && 'opacity-0'
        )}
      >
        {column.columnDef.filterFn === 'inNumberRange' ? (
          <div className="divide-y divide-gray-500/20 text-sm">
            <div className="flex divide-x divide-gray-100 dark:divide-gray-700">
              <RangeFilter column={column} table={table} />
            </div>
          </div>
        ) : column.columnDef.filterFn === 'arrIncludesAll' ||
          column.columnDef.filterFn === 'equals' ||
          column.columnDef.filterFn === 'arrIncludesSome' ? (
          <div className="w-full divide-y divide-gray-500/20 text-sm">
            <div className="flex divide-x divide-gray-100 dark:divide-gray-700">
              <SelectFilter column={column} table={table} multi />
            </div>
          </div>
        ) : column.columnDef.filterFn === 'fuzzy' ||
          column.columnDef.filterFn === 'includesString' ? (
          <div className="w-full divide-y divide-gray-100 dark:divide-gray-700">
            <TextFilter column={column} table={table} />
          </div>
        ) : (
          <div className="w-full divide-y divide-gray-500/20 text-sm">
            <div className="flex divide-x divide-gray-100 dark:divide-gray-700">
              <SelectFilter column={column} table={table} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export function TextFilter({
  column,
}: {
  table: Table<any>
  column: Column<any, any>
}) {
  const value = column.getFilterValue() as string
  // const optionCount = column.getFacetedUniqueValues().size
  // const showReset = !!column.getFilterValue()

  return (
    <>
      <PlainInput
        debounce={500}
        value={value}
        onChange={e => column.setFilterValue(e.currentTarget.value || null)}
        placeholder={`Search...`}
        className="flex-1 p-1 focus:bg-transparent"
      />
    </>
  )
}

export function RangeFilter({
  column,
}: {
  table: Table<any>
  column: Column<any, any>
}) {
  const minMaxRanges = column.getFacetedMinMaxValues() ?? []
  const filterValue =
    (column.getFilterValue() as undefined | [number, number]) ?? ([] as [])
  const [min, max] = filterValue
  // const showReset = filterValue.some(v => !!v)

  return (
    <div className="flex flex-1 items-center">
      <NumberInputWrap className="flex-1">
        <NumberInput
          value={min}
          placeholder={`Min (${formatNumber(minMaxRanges[0] ?? NaN)})`}
          onChange={value => {
            column.setFilterValue((prev: any) => [value, prev?.[1]])
          }}
          Input={PlainInput}
          min={minMaxRanges[0]}
          max={minMaxRanges[1]}
          allowUndefined
          className="p-1"
        />
      </NumberInputWrap>
      <NumberInputWrap className="flex-1">
        <NumberInput
          value={max}
          placeholder={`Max (${formatNumber(minMaxRanges[1] ?? NaN)})`}
          onChange={value => {
            column.setFilterValue((prev: any) => [prev?.[0], value])
          }}
          Input={PlainInput}
          min={minMaxRanges[0]}
          max={minMaxRanges[1]}
          allowUndefined
          className="p-1"
        />
      </NumberInputWrap>
    </div>
  )
}

export function SelectFilter({
  column,
  table,
  multi,
}: {
  table: Table<any>
  column: Column<any, any>
  multi?: any
}) {
  const uniqueValues = column.getFacetedUniqueValues()
  const getFilterLabel = column.columnDef.meta?.getFilterLabel
  const options = React.useMemo(() => {
    return Array.from(uniqueValues.entries()).map(([uniqueValue, count]) => ({
      label: `${
        getFilterLabel?.(uniqueValue, { column, table }) ?? uniqueValue
      } (${count})`,
      value: uniqueValue,
    }))
  }, [column, getFilterLabel, table, uniqueValues])

  const filterValue = column.getFilterValue()

  // const showReset = Array.isArray(filterValue)
  //   ? filterValue.length
  //   : !!filterValue

  return (
    <div className="flex items-center">
      <Select
        multi={multi}
        value={filterValue}
        options={options}
        onChange={(value: unknown) => column.setFilterValue(value)}
        CustomInput={PlainInput}
        placeholder="Select..."
        className="flex-1"
        inputProps={{
          className: 'p-1 text-xs',
        }}
      />
    </div>
  )
}
