import { mapObjValues } from '../utils'
import { validateCondition } from './Validate'

// New exports

export const conditionStageOptions = [
  { label: 'Brand Type', value: 'brandType' },
  { label: 'Brand', value: 'brand' },
  { label: 'Brand Property', value: 'brandProperty' },
  { label: 'Keyword', value: 'keyword' },
  { label: 'Search', value: 'search' },
  { label: 'Result', value: 'result' },
]

// export function setConditionStage(conditions, stage, updater) {
//   return {
//     ...conditions,
//     [stage]: functionalUpdate(updater, conditions?.[stage]),
//   }
// }

// export function mergeConditions(conditionsArray) {
//   const allConditions = {}

//   conditionStageOptions.forEach(({ value: stage }) => {
//     allConditions[stage] = flattenCondition(
//       makeAllCondition(conditionsArray.map(conditions => conditions?.[stage])),
//       { removeEmpty: true }
//     )
//   })

//   return filterObj(allConditions, Boolean)
// }

export function makeColumnCondition(columnId: any, operator: any, values: any) {
  if (!Array.isArray(values)) {
    if (values) {
      values = [values]
    }
    values = []
  }

  return {
    matchType: 'expression',
    columnId,
    operator,
    values,
  }
}

export function flattenCondition(
  condition: any,
  { maxDepth = 100000, removeEmpty = false } = {},
  depth = 1
) {
  if (!condition) {
    return undefined
  }

  if (depth > maxDepth) {
    return condition
  }

  if (condition.matchType === 'expression') {
    if (!condition.values?.length) {
      return undefined
    }

    return condition
  }

  if (!condition.conditions?.length) {
    return undefined
  }

  // Flatten same type conditions into parent
  tryFlatten()

  function tryFlatten() {
    let flatSubConditions: any = []
    let found

    condition.conditions.forEach((subCondition: any) => {
      if (subCondition?.matchType === condition.matchType) {
        found = true
        flatSubConditions = [
          ...flatSubConditions,
          ...(subCondition.conditions ?? []),
        ]
      } else {
        flatSubConditions.push(subCondition)
      }
    })

    condition.conditions = flatSubConditions

    if (found) {
      tryFlatten()
    }
  }

  // Try to flatten grandchildren
  condition.conditions = condition.conditions
    .filter(removeEmpty ? Boolean : () => true)
    .map((subCondition: any) => {
      return flattenCondition(
        subCondition,
        { maxDepth, removeEmpty },
        depth + 1
      )
    })

  // Extract single-condition groupings into a single condition

  if (condition.conditions.length === 1) {
    return condition.conditions[0]
  }

  // condition.conditions = sortBy(condition.conditions, d => {
  //   return d.matchType === 'expression'
  //     ? -2
  //     : d.matchType === 'any'
  //     ? -1
  //     : 0
  // })

  return condition
}

export function makeAllCondition(conditions: any) {
  conditions = conditions.filter(Boolean)

  return flattenCondition(
    {
      matchType: 'all',
      conditions,
    },
    { maxDepth: 1 }
  )
}

export function makeAnyCondition(conditions: any) {
  conditions = conditions.filter(Boolean)

  return flattenCondition(
    {
      matchType: 'any',
      conditions,
    },
    { maxDepth: 1 }
  )
}

// export function makeMinCondition(min = 1, conditions) {
//   return {
//     matchType: 'number',
//     matchTypeVal: min,
//     conditions: conditions.filter(Boolean),
//   }
// }

// export function makePercentageCondition(percentage, conditions) {
//   if (!percentage) {
//     throw new Error('A percentage is required for percentage conditions')
//   }
//   return {
//     matchType: 'percentage',
//     matchTypeVal: percentage,
//     conditions: conditions.filter(Boolean),
//   }
// }

export function filterCondition(condition: any, predicate: any, depth = 0) {
  if (condition.conditions) {
    const conditions = condition.conditions.filter((subCondition: any) => {
      return filterCondition(subCondition, predicate, depth + 1)
    })

    if (conditions.length) {
      return {
        ...condition,
        conditions,
      }
    }

    return undefined
  }

  return predicate(condition, depth) ? condition : undefined
}

export function someCondition(condition: any, predicate: any, depth = 0) {
  if (!condition) {
    return false
  }

  if (condition?.conditions) {
    return condition.conditions.some((subCondition: any) => {
      return someCondition(subCondition, predicate, depth + 1)
    })
  }

  return !!predicate(condition, depth)
}

export function recursiveValidateConditions(conditions: any) {
  Object.keys(conditions).forEach(key =>
    conditions[key] === null ? delete conditions[key] : {}
  )
  return mapObjValues(conditions ?? {}, d => validateCondition()(d))
}
